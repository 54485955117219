<template>
  <div
    ref="giftListRef"
    class="gift-list"
    :class="{'gift-list__small': type === 1}"
  >
    <GiftListItem
      v-for="(item, index) in showGiftList"
      :key="index"
      :item="item"
      :promotion-id="promotionId"
      :promotion-type="promotionType"
      :fulfill-status="fulfillStatus"
    />
    <div
      v-if="isShowViewAllBtn"
      v-expose="{ id: '1-7-5-22', data: { promotion_id: promotionId, promotion_type: promotionType, show_position: showPosition } }"
      class="view-all"
      @click="handleViewAll"
    >
      <sui_icon_viewmore_right_40px
        size="40px"
        :is-rotate="GBCssRight"
      />
      <span class="view-all-text">{{ language.SHEIN_KEY_PC_26297 }}</span>
    </div>
  </div>
</template>
<script>
import GiftListItem from './GiftListItem.vue'
import { mapState } from 'vuex'
import { daEventCenter } from '../../../../../services/eventCenter'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { sui_icon_viewmore_right_40px } from '@shein-aidc/icon-vue3'

export default {
  components: {
    GiftListItem,
    sui_icon_viewmore_right_40px
  },
  props: {
    giftList: {
      type: Array,
      default(){
        return []
      }
    },
    type: {
      type: Number,
      default: 0
    },
    promotionId: {
      type: [String, Number],
      require: true,
      default: ''
    },
    promotionType: {
      type: [String, Number],
      require: true,
      default: ''
    },
    fulfillStatus: {
      type: Number,
      require: true,
      default: 0
    },
    isShow: {
      type: [Number, Boolean],
      default: true
    }
  },
  data() {
    return {
      showGiftListLength: 10,
      isShowViewAllBtn: false, // 是否展示查看全部赠品按钮
    }
  },
  computed: {
    ...mapState(['language', 'locals']),
    // 展示出来的赠品列表（最多展示9个）
    showGiftList() {
      return this.giftList.slice(0, this.showGiftListLength)
    },
    showPosition() {
      return this.promotionType == 4 ? 1 : '-'
    },
    GBCssRight() {
      return this.locals.GB_cssRight || gbCommonInfo.GB_cssRight
    }
  },
  watch: {
    giftList: {
      handler() {
        if(typeof window === 'undefined') return
        setTimeout(() => {
          const maxShowGiftListLength = Math.floor(this.$refs.giftListRef.offsetWidth / 74)
          if(maxShowGiftListLength >= this.giftList.length) {
            this.isShowViewAllBtn = false
            this.showGiftListLength = maxShowGiftListLength
          } else {
            this.isShowViewAllBtn = true
            this.showGiftListLength = maxShowGiftListLength - 1
          }
        }, 100)      
      },
      immediate: true,
      deep: true
    }
  },
  provide() {
    return {
      cutImg: this.cutImg
    }
  },
  methods: {
    handleViewAll() {
      daEventCenter.triggerNotice({
        daId: '1-7-5-23',
        extraData: {
          promotion_id: this.promotionId,
          promotion_type: this.promotionType,
          show_position: this.showPosition
        }
      })
      this.$emit('handleViewAll')
    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.locals.RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
  },
  emits: ['handleViewAll']
}
</script>
<style lang="less" scoped>
.gift-list {
  display: flex;
  width: 100%;
  height: 108px;
  &__small {
    margin-top: 12px;
    height: 96px;
  }
  .view-all {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 96px;
    padding: 0 10px;
    cursor: pointer;
    /* stylelint-disable-next-line selector-nested-pattern */
    &-text {
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }
  }
}
</style>
