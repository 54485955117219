<template>
  <swiper-container
    v-if="show"
    ref="swiperContainer"
    init="false"
    class="cart-item-swiper"
  >
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <div slot="container-start">
      <slot name="button-prev">
        <div
          ref="swiperButtonPrev"
          class="swiper-button-prev swiper-button-disabled j-r-prev"
          tabindex="0"
          aria-label="prev page"
        >
          <sui_icon_more_left_18px
            :size="uiConfig.iconSize || '18px'"
            :is-rotate="locals.GB_cssRight"
          />
        </div>
      </slot>
    </div>
    <swiper-slide
      v-for="(item, index) in items"
      :key="index"
    >
      <slot
        :item="item"
        :index="index"
      ></slot>
    </swiper-slide>
    <div slot="container-end">
      <slot name="button-next">
        <div
          ref="swiperButtonNext"
          class="swiper-button-next j-r-next"
          tabindex="0"
          aria-label="next page"
        >
          <sui_icon_more_right_18px
            :size="uiConfig.iconSize || '18px'"
            :is-rotate="locals.GB_cssRight"
          />
        </div>
      </slot>
    </div>
  </swiper-container>
</template>

<script>
import { sui_icon_more_left_18px, sui_icon_more_right_18px } from '@shein-aidc/icon-vue3'
import { Navigation } from 'swiper/modules'

export default {
  name: 'CartItemSwiper',
  components: {
    sui_icon_more_left_18px,
    sui_icon_more_right_18px
  },
  props: {
    items: {
      type: Array,
      default(){
        return []
      }
    },
    swiperConfig: {
      type: Object,
      default(){
        return {}
      }
    },
    uiConfig: {
      type: Object,
      default(){
        return {}
      }
    },
    locals: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      show: true,
      swiper: null,
    }
  },
  watch: {
    items: {
      handler(val){
        if(typeof window === 'undefined') return
        if(!val.length) return
        if(this.swiper) {
          this.swiper.destroy?.()
          this.show = false
        }
        this.swiper = null
        this.$nextTick(() => {
          this.show = true
          this.$nextTick(() => {
            this.initSwiper()
          })
        })
      },
      immediate: true,
    }
  },
  methods: {
    initSwiper() {
      if (!this.$refs.swiperContainer) return

      let swiperConfig = {
        modules: [Navigation],
        navigation: {
          prevEl: this.$refs.swiperButtonPrev,
          nextEl: this.$refs.swiperButtonNext,
        },
        direction: 'horizontal',
        slidesPerView: 'auto',
        slidesPerGroup: 6,
        spaceBetween: 5,
        roundLengths: false,
        observer: true,
        observeParents: true,
        ...this.swiperConfig,
      }
      Object.assign(this.$refs.swiperContainer, swiperConfig)
      this.$refs.swiperContainer?.initialize?.()
      this.swiper = this.$refs.swiperContainer?.swiper
    }
  }
}
</script>

<style lang="less" scoped>
.cart-item-swiper {
  swiper-slide {
    width: max-content;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 45%;
    width: 32px;
    height: 32px;
    background: rgba(255,255,255,.94);
    border-radius: 18px;
    font-size: 12px;
    text-align: center;
    vertical-align: unset;

    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: normal;

    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
    font-size: 18px;
    color: #222;
  }
  .swiper-button-prev {
    .left(5px);
  }
  .swiper-button-next {
    .right(5px);
  }
}
</style>
